import { Box, Button, CircularProgress, Divider, IconButton, Modal, Typography } from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { renderAsync } from 'docx-preview';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Download, Person, Upload } from 'styled-icons/material';

const ResumeModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

const ResumeContainer = styled(Box)`
  position: relative;
  width: 700px;
  background: #ffffff;
  padding: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px; 
  overflow-y: auto; 
  max-height: 90vh; 
`;

const DownloadButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #325cd6 !important;
  z-index: 1;
  color: #fff !important;
  &:hover {
    background-color: #1a3f98 !important;
  }
`;

const Tag = styled.span<{ background?: string }>`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${({ background }) => background || '#81c784'};
  color: #2e7d32;
  font-size: 12px;
  font-weight: bold;
  margin: 8px;
`;

export const ActionButton = styled(Button)`
  margin: 8px 8px 0 0;
  background-color: #f0f4ff !important;
  color: #325cd6 !important;
  text-transform: none;
  &:hover {
    background-color: #e6ecff !important;
  }

  &.Mui-disabled {
    background-color: #d3d3d3 !important;
    color: #a9a9a9 !important;
  }
`;

const HeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap; 
`;

const DocxWrapper = styled.div`
  .docx-wrapper {
    flex-flow: initial !important;
    align-items: initial !important;
  }
`;

interface AIResumeEvaluationProps {
    aiResumeEvaluation: {
        careerProgressionDetails: string;
        careerProgress: string;
        universityScore: number;
        jobMatchScore: number | null;
        resumeUrl: string;
        resumeParsingCompleted?: boolean | null;
    };
    onUpdateResume: () => void;
}

const AIResumeEvaluationComponent: React.FC<AIResumeEvaluationProps> = ({
    aiResumeEvaluation,
    onUpdateResume    
}) => {
    const { careerProgress, universityScore, jobMatchScore, careerProgressionDetails, resumeUrl } = aiResumeEvaluation;
    const [showModal, setShowModal] = useState(false);
    const [isDocx, setIsDocx] = useState(false);
    const [loading, setLoading] = useState(false);
    const docxContainerRef = useRef<HTMLDivElement>(null);

    const handleViewResume = async () => {
        if (!resumeUrl) {
            console.error("Resume URL is undefined or empty.");
            return;
        }
    
        try {
            const urlPath = resumeUrl.split('?')[0]; 
            const fileExtension = urlPath.split('.').pop()?.toLowerCase(); 
    
            if (!fileExtension) {
                console.error("Unable to determine the file extension.");
                return;
            }
    
            setShowModal(true);
            setLoading(true);
    
            if (fileExtension === 'docx') {
                setIsDocx(true);
                try {
                    const response = await fetch(resumeUrl);
                    if (!response.ok) {
                        throw new Error(`Failed to fetch the resume. Status: ${response.status}`);
                    }
                    const arrayBuffer = await response.arrayBuffer();
                    if (docxContainerRef.current) {
                        await renderAsync(arrayBuffer, docxContainerRef.current); 
                    }
                } catch (error) {
                    console.error('Error loading DOCX file:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setIsDocx(false);
                setLoading(false); 
            }
        } catch (error) {
            console.error("An error occurred in handleViewResume:", error);
        }
    };
    

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDownloadResume = () => {
        if (!resumeUrl) {
            console.error("Resume URL is undefined or empty.");
            return;
        }
    
        try {
            const fileName = resumeUrl.endsWith('.docx') ? 'resume.docx' : 'resume.pdf'; 
            const link = document.createElement('a');
            link.href = resumeUrl; 
            link.download = fileName; 
            document.body.appendChild(link);
            link.click(); 
            document.body.removeChild(link); 
        } catch (error) {
            console.error("An error occurred in handleDownloadResume:", error);
        }
    };
    
   
    const handleUpdateResume = () => {
        onUpdateResume();
    };

    return (
        <Box>
            {aiResumeEvaluation?.resumeParsingCompleted && <HeaderContainer mb={1}>
                {resumeUrl ? (
                 <Tag background="#e0f2f1">{careerProgress}</Tag>
                ) : null}  
                <Tag background="#e0f7fa">University Score: {universityScore}</Tag>
                {jobMatchScore !== null && <Tag background="#e8f5e9">Job Match Score: {jobMatchScore}%</Tag>}
            </HeaderContainer> || null}
            <Typography variant="body2" color="textSecondary" mb={2}>
                {careerProgressionDetails}
            </Typography>

            <Box display="flex" alignItems="center">
                <ActionButton
                    className='mr-2'
                    variant="contained"
                    onClick={handleViewResume}
                    startIcon={<Person color="#325CD6" size="24" />}
                    disabled={!resumeUrl}
                >
                    View Resume
                </ActionButton>
                
                <ActionButton
                    className='mr-2'
                    variant="contained"
                    onClick={handleDownloadResume}
                    startIcon={<Download size="24" />}
                    disabled={!resumeUrl}
                >
                    Download
                </ActionButton>
                    <ActionButton
                    className='mr-2'
                    variant="contained"
                    onClick={handleUpdateResume}
                    startIcon={<Upload size="24" />}
                >
                    Update
                </ActionButton>
                
            </Box>

            <ResumeModal open={showModal} onClose={handleCloseModal}>
                <ResumeContainer>
                    <DownloadButton onClick={handleDownloadResume}>
                        <Download size="24" />
                    </DownloadButton>
                    {loading && (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    )}
                    {!isDocx ? (
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                            <Viewer fileUrl={resumeUrl} />
                        </Worker>
                    ) : (
                        <DocxWrapper>
                            <div ref={docxContainerRef} style={{ padding: '20px' }} />
                        </DocxWrapper>
                    )}
                </ResumeContainer>
            </ResumeModal>

            <Divider sx={{ my: 2 }} />
        </Box>
    );
};

export default AIResumeEvaluationComponent;