import { Badge, Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { AlarmFill } from '@styled-icons/bootstrap/AlarmFill';
import { ArrowsMove } from '@styled-icons/bootstrap/ArrowsMove';
import { CalendarPlusFill } from '@styled-icons/bootstrap/CalendarPlusFill';
import { ChevronDoubleRight } from '@styled-icons/bootstrap/ChevronDoubleRight';
import { CloudDownloadFill } from '@styled-icons/bootstrap/CloudDownloadFill';
import { CloudUploadFill } from '@styled-icons/bootstrap/CloudUploadFill';
import { InfoCircleFill } from '@styled-icons/bootstrap/InfoCircleFill';
import { MailSend } from '@styled-icons/boxicons-regular/MailSend';
import { BellRing } from '@styled-icons/boxicons-solid/BellRing';
import { Cross } from '@styled-icons/entypo/Cross';
import { Globe } from "@styled-icons/entypo/Globe";
import { Lock } from "@styled-icons/entypo/Lock";
import { InfoOutline } from '@styled-icons/evaicons-outline/InfoOutline';
import { MoreVerticalOutline } from '@styled-icons/evaicons-outline/MoreVerticalOutline';
import { PersonFeedback } from '@styled-icons/fluentui-system-regular/PersonFeedback';
import { DoubleArrow } from '@styled-icons/material-sharp/DoubleArrow';
import { KeyboardBackspace } from '@styled-icons/material/KeyboardBackspace';
import { incrementReminderCount, resetChangeNotification, setFetchJobs, setRefetchCandidates, setSelectedCandidateForBookMeetingProcess } from 'actions/hiringManager/jobs/jobsSlice';
import { LightSpan } from 'components/CommonStyles';
import { CreateCandidateMeetingRequest } from 'containers/Candidate/ICandidate';
import useJobDispatcher, { JOB_ACCESS_CONSTANT } from 'containers/HiringManager/Jobs.util';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { candidateService } from 'services/candidate';
import { RootState } from 'store';
import { AddComment, Check, Delete, Person } from 'styled-icons/material';
import { JobCandidate, JobSearchResult, STATUS_TYPE } from 'types/Jobs';
import { buildUrlWithParams } from 'utilities/commonUtils';
import { API_STATUSES, BASE_PORTAL_URL, DASHBOARD_URL_CANDIDATE_APP, DEFAULT_TOKEN, IS_SIMULATION_MODE } from 'utilities/constants';
import { StarBadgeIcon } from '../../../assets';
import { messages, theme } from '../constants';
import AddCommentModal from './AddComment/AddComment';
import { CapabilitiesModal, CapabilityLink } from './CapabilitiesModal';
import { ConfirmationModal, ConfirmationProps } from './ConfirmationModal';
import { MeetingScheduleBy } from './CreateCandidateWorkFlow/CreateCandidate';
import { CreateCandidateWorkFlow, CreateCandidateWorkflowSteps } from './CreateCandidateWorkFlow/index';
import DownloadResume from './DownloadResume/DownloadResume';
import InformationModal from './InformationModal';
import {
    ActionIconsWrapper,
    CandidateCardsContainer,
    CandidatesButton,
    CardTitleWrapper,
    FastTrackButtonContainer,
    FastTracked,
    JobCandidatesWrapper,
    Label,
    LogoImage,
    NavButton,
    NavButtonContainer,
    NavButtonLabel,
    ProgressBar,
    ProgressBarItem,
    RejectedTracked,
    TypeWrapper,
    W100
} from './Jobs.styled';
import { CardSubwrapper, CardWrapper } from './JobsContainer';
import MeetingDetails from './MeetingDetails';
import SendCustomReminder from './SendCustomReminder/SendCustomReminder';
import { ToastNotification } from './ToastNotification';
import UploadResume from './UploadResume/UploadResume';
import { isNumber, round } from 'lodash';

type Props = {
    job: JobSearchResult;
    publicJobsFilter?: boolean;
};

const jobsContext = React.createContext<{ showNewImprovedUI?: boolean }>({});

type CardTitleType = {
    name: string;
    tooltipText: string;
    value: STATUS_TYPE;
    secondaryValue?: STATUS_TYPE
};

const cardsTitle: { [key: string]: CardTitleType } = {
    'TalentPool': { name: 'Talent Pool', tooltipText: 'Add your external candidates here', value: 'TALENT_POOL' },
    'PreVetted': { name: 'Pre-Vetted Candidates', tooltipText: 'Candidates who meet or exceed your set bar', value: 'MOVE_TO_PRE_VETTED_CANDIDATE', secondaryValue: 'PRE_VETTED_CANDIDATE' },
    'Vetted': { name: 'Vetted Candidates', tooltipText: 'Candidates who have been sent emails or have shown interest in this job', value: "VETTED_CANDIDATE" },
    'Interview': { name: 'Interview', tooltipText: 'Send interview reminders and finalise and reject the candidates', value: "INTERVIEW_REQUEST" },
    'Finalised': { name: 'Finalised Candidates', tooltipText: 'List of finalised candidates', value: "FINALIZED_CANDIDATE" },
    'Rejected': { name: 'Rejected Candidates', tooltipText: 'List of rejected candidates', value: "REJECTED_CANDIDATE" },
};

const secondaryBtnWidth = '16px';

export const JobCandidates = ({ job, publicJobsFilter }: Props) => {
    const {
        jobs: {
            changeNotification,
            vettedCandidates,
            talentPoolCandidates,
            interviewRequestCandidates,
            rejectedCandidates,
            finalizeCandidates,
            refetchCandidates,
            preVettedCandidates
        },
        auth: { user: { expertId } }
    } = useSelector((state: RootState) => ({ jobs: state.jobs, auth: state.auth }));
    const { getJobCandidates } = useJobDispatcher();
    const dispatch = useDispatch();
    const [showNotification, setShowNotification] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState<{ status: STATUS_TYPE | string, fastTrack: boolean }>({ status: '', fastTrack: false });
    const jobId = job.jobId;
    const totalProcesses = publicJobsFilter ? 4 : 5;
    let stepCounter = 1;
    const history = useHistory();
    const targetDivRef = useRef<any>();
    const [divHeight, setDivHeight] = useState(0);

    useEffect(() => {
        if (refetchCandidates) {
            getJobCandidates({ jobId });
            dispatch(setRefetchCandidates({ refetchCandidates: false }));
        }
    });

    useEffect(() => {
        if (changeNotification.show && changeNotification.status) {
            setNotificationStatus({ status: changeNotification.status, fastTrack: changeNotification.fastTrack })
            setShowNotification(true);
            dispatch(resetChangeNotification());
            setEmailSent(changeNotification.emailSent);
        }
    }, [changeNotification, refetchCandidates]);

    useEffect(() => {
        if (targetDivRef.current) {
            const viewportHeight = window.innerHeight;
            const padding = 90;
            const maxHeight = viewportHeight - padding - targetDivRef.current.offsetTop;
            setDivHeight(maxHeight);
        }
    }, []);

    const onBackClick = () => {
        dispatch(setFetchJobs({ fetchJobs: true }));
        history?.goBack();
    };


    return (
        <jobsContext.Provider value={{}}>
            <Nav onBackClick={onBackClick} jobAccess={job.jobAccess} />
            <div ref={targetDivRef} className="overflow-auto" style={{ height: `${divHeight}px` }}>
                <JobCandidatesWrapper
                    className='job-candidates-wrapper'
                    isMobile={isMobile}>
                    <TalentPoolType cards={talentPoolCandidates} jobId={jobId} expertId={expertId} level={stepCounter++} totalProcesses={totalProcesses} />
                    {!publicJobsFilter && <PreVettedType cards={preVettedCandidates} jobId={jobId} expertId={expertId} level={stepCounter++} totalProcesses={totalProcesses} />}
                    <VettedType cards={vettedCandidates} jobId={jobId} expertId={expertId} level={stepCounter++} totalProcesses={totalProcesses} />
                    <InteviewType cards={interviewRequestCandidates} jobId={jobId} expertId={expertId} level={stepCounter++} totalProcesses={totalProcesses} />
                    <TypeWrapper bg={theme.colors.FINALIZED} isMobile={isMobile}>
                        <CandidateCardsList level={stepCounter++} title={cardsTitle.Finalised} isFinalized cards={finalizeCandidates} expertId={expertId} jobId={jobId} totalProcesses={totalProcesses} />
                    </TypeWrapper>
                    <TypeWrapper mr={0} bg={theme.colors.REJECTED} isMobile={isMobile}>
                        <CandidateCardsList level={-1} title={cardsTitle.Rejected} isRejected cards={rejectedCandidates} expertId={expertId} jobId={jobId} totalProcesses={totalProcesses} />
                    </TypeWrapper>
                </JobCandidatesWrapper>
            </div>
            {showNotification &&
                <ToastNotification
                    show={showNotification}
                    setShow={setShowNotification}
                    status={notificationStatus.status as STATUS_TYPE}
                    fastTrack={notificationStatus.fastTrack}
                    emailSent={emailSent}
                />
            }
        </jobsContext.Provider>
    )
};

const PreVettedType = ({ cards, jobId, expertId, level, totalProcesses }: { cards: JobCandidate[], jobId: string, expertId: string, level: number, totalProcesses: number }) => {
    const { changeCandidateStatus } = useJobDispatcher();
    const sortedCards = cards.slice().sort((card) => card.status === 'PRE_VETTED_CANDIDATE' ? -1 : 1);

    const moveToVetted = (candidateId: string, reason: string, fastTrack: boolean) => {
        changeCandidateStatus({ candidateId, reason, jobId, status: 'PRE_VETTED_CANDIDATE', fastTrack, sendEmail: true });
    };

    return (
        <TypeWrapper bg={theme.colors.PRE_VETTED} isMobile={isMobile}>
            <CandidateCardsList level={level} title={cardsTitle.PreVetted} cards={sortedCards} expertId={expertId} jobId={jobId}
                preVettedProps={{
                    moveToVetted: moveToVetted,
                    isPreVetted: true
                }}
                totalProcesses={totalProcesses}
            />
        </TypeWrapper>
    );
};

const TalentPoolType = ({ cards, jobId, expertId, level, totalProcesses }: { cards: JobCandidate[], jobId: string, expertId: string, level: number, totalProcesses: number }) => {
    const { changeCandidateStatus, sendReminderToCandidate } = useJobDispatcher();

    const handleTriggerFastTrack = (candidateId: string, reason: string, fastTrack: boolean) => {
        changeCandidateStatus({ candidateId, reason, jobId, status: 'TALENT_POOL', fastTrack: true, sendEmail: true });
    };

    const handleSendReminder = (candidateId: string, reason: string, fastTrack: boolean) => {
        sendReminderToCandidate({ candidateId, jobId, status: 'TALENT_POOL', sendEmail: true });
    };

    const talentPoolProps = {
        isTalentPool: true,
        handleTriggerFastTrack: handleTriggerFastTrack,
        handleSendReminder: handleSendReminder,
    };

    return (
        <TypeWrapper bg={theme.colors.TALENT_POOL} isMobile={isMobile}>
            <CandidateCardsList level={level} talentPoolProps={talentPoolProps} title={cardsTitle.TalentPool} cards={cards} expertId={expertId} jobId={jobId} totalProcesses={totalProcesses} />
        </TypeWrapper>
    );
};

const VettedType = ({ cards, jobId, expertId, level, totalProcesses }: { cards: JobCandidate[], jobId: string, expertId: string, level: number, totalProcesses: number }) => {
    const { changeCandidateStatus } = useJobDispatcher();

    const handleSendInterviewRequest = (candidateId: string, reason: string, fastTrack: boolean) => {
        changeCandidateStatus({ candidateId, reason, jobId, status: 'INTERVIEW_REQUEST', fastTrack, sendEmail: true });
    };

    const vettedProps = {
        isVetted: true,
        sendInterviewRequest: handleSendInterviewRequest
    };

    const sortedCards = cards.slice().sort((card) => card.status === 'VETTED_ACCEPTED' ? -1 : 1);

    return (
        <TypeWrapper bg={theme.colors.VETTED} isMobile={isMobile}>
            <CandidateCardsList vettedProps={vettedProps} level={level} title={cardsTitle.Vetted} cards={sortedCards} expertId={expertId} jobId={jobId} totalProcesses={totalProcesses} />
        </TypeWrapper>
    );
};

const InteviewType = ({ cards, jobId, expertId, level, totalProcesses }: { cards: JobCandidate[], jobId: string, expertId: string, level: number, totalProcesses: number }) => {
    const { changeCandidateStatus } = useJobDispatcher();

    const sendReminder = (candidateId: string, reason: string, fastTrack: boolean) => {
        changeCandidateStatus({ candidateId, reason, jobId, status: 'REMINDER', fastTrack, sendEmail: true });
    };

    const onFinalise = (candidateId: string, reason: string, fastTrack: boolean) => {
        changeCandidateStatus({ candidateId, reason, jobId, status: 'FINALIZED_CANDIDATE', fastTrack, sendEmail: true });
    };

    const onReject = (candidateId: string, reason: string, fastTrack: boolean) => {
        changeCandidateStatus({ candidateId, reason, jobId, status: 'REJECTED_CANDIDATE', fastTrack, sendEmail: true });
    };

    const interviewProps = {
        onFinalise,
        onReject,
        sendReminder,
        isInterview: true,
    };

    return (
        <TypeWrapper bg={theme.colors.INTERVIEW} isMobile={isMobile}>
            <CandidateCardsList interviewProps={interviewProps} level={level} title={cardsTitle.Interview} cards={cards} expertId={expertId} jobId={jobId} totalProcesses={totalProcesses} />
        </TypeWrapper>
    );
};

type TalentPoolProps = {
    isTalentPool: boolean;
    handleTriggerFastTrack: (candidateId: string, reason: string, fastTrack: boolean) => void;
    handleSendReminder: (candidateId: string, reason: string, fastTrack: boolean) => void;
}

type InterviewProps = {
    isInterview: boolean;
    sendReminder: (candidateId: string, reason: string, fastTrack: boolean) => void;
    onFinalise: (candidateId: string, reason: string, fastTrack: boolean) => void;
    onReject: (candidateId: string, reason: string, fastTrack: boolean) => void;
}

type VettedProps = {
    isVetted: boolean;
    sendInterviewRequest: (candidateId: string, reason: string, fastTrack: boolean) => void;
}

type PreVettedProps = {
    isPreVetted: boolean;
    moveToVetted: (candidateId: string, reason: string, fastTrack: boolean) => void;
}

type CandidateCardsListType = {
    title: CardTitleType;
    cards: JobCandidate[];
    jobId: string;
    totalProcesses: number;
    talentPoolProps?: TalentPoolProps;
    vettedProps?: VettedProps;
    preVettedProps?: PreVettedProps;
    interviewProps?: InterviewProps;
    level: number;
    expertId: string;
    isRejected?: boolean;
    isFinalized?: boolean;
};

const CandidateCardsList = ({ title,
    cards,
    jobId,
    talentPoolProps,
    vettedProps,
    interviewProps,
    level,
    expertId,
    isFinalized,
    isRejected,
    preVettedProps,
    totalProcesses }: CandidateCardsListType) => {
    const { findJobFromJobId, getJobCandidates, updateCandidateInfoDispatcher } = useJobDispatcher();
    const trackId = findJobFromJobId(jobId)?.trackId;
    const [meetingDetailsProps, setMeetingDetailsProps] = useState<{ jobId: string, candidateId: string } | null>();
    const [startWorkFlow, setStartWorkFlow] = useState<CreateCandidateWorkflowSteps | undefined>();
    const [showCreateCandidate, setShowCreateCandidate] = useState<boolean>(false);

    const handleShowMeetingDetails = (candidateId: string) => {
        setMeetingDetailsProps({ candidateId, jobId });
    }

    const handleCreateCandidate = (workflowStep?: CreateCandidateWorkflowSteps) => {
        if (workflowStep) {
            setStartWorkFlow(workflowStep);
        } else {
            setStartWorkFlow(CreateCandidateWorkflowSteps.CREATE_CANDIDATE);
        }
        setShowCreateCandidate(true);
    }

    const handleMeetingBooked = (payload: CreateCandidateMeetingRequest) => {
        candidateService.createMeeting(payload)
            .then(() => {
                getJobCandidates({ jobId });
                setShowCreateCandidate(false);
                toast.success("Meeting booked successfully!");
            })
            .catch(() => {
                toast.success("Failed to book the meeting please try again.");
            })
    }

    const updateCandidateResume = (candidateState: STATUS_TYPE, candidateId: string, url: string) => {
        updateCandidateInfoDispatcher(candidateState, candidateId, { resumeUrl: url });
    }

    return (
        <div>
            {meetingDetailsProps && (
                <MeetingDetails {...meetingDetailsProps} onClose={() => setMeetingDetailsProps(null)} />
            )}
            <ProgressBar>
                {Array.from(Array(totalProcesses).keys()).map((o, index) => {
                    const colorCode = index + 1 <= level ? `PROGRESS_0${index + 1}` : `PROGRESS_00`;
                    return <ProgressBarItem className='progress-bar-item' bg={theme.colors[colorCode]} />
                })}
            </ProgressBar>
            <div className='font-weight-bold d-flex justify-content-between'>
                <CardTitleWrapper>
                    <div className='font-weight-bold selection-title' style={{ fontSize: '18px' }}>{title.name}</div>
                    <InfoOutline data-tip data-for={title.name} />
                </CardTitleWrapper>
                <div>{cards.length}</div>
            </div>
            <CandidateCardsContainer>
                {talentPoolProps?.isTalentPool && (
                    <CandidatesButton mb={20} variant='primary' onClick={() => handleCreateCandidate()}>
                        Add Candidate
                    </CandidatesButton>
                )}
                {cards.map(card => {
                    const showBadge = card.status === 'VETTED_ACCEPTED';
                    const isVettedDisabled = card.status === 'VETTED_CANDIDATE' && card.candidatePrivacy;
                    return (
                        <CandidateCard
                            showBadge={showBadge}
                            isVettedDisabled={isVettedDisabled}
                            {...card}
                            expertId={expertId}
                            talentPoolProps={talentPoolProps}
                            vettedProps={vettedProps}
                            preVettedProps={preVettedProps}
                            interviewProps={interviewProps}
                            isFinalized={isFinalized}
                            isRejected={isRejected}
                            trackId={trackId!}
                            handleShowMeetingDetails={handleShowMeetingDetails}
                            jobId={jobId}
                            handleCreateCandidate={handleCreateCandidate}
                        />
                    )
                })}
            </CandidateCardsContainer>
            <ReactTooltip id={title.name} type="dark">
                {title.tooltipText}
            </ReactTooltip>
            {showCreateCandidate && <CreateCandidateWorkFlow
                setShowCreateCandidate={setShowCreateCandidate}
                jobId={jobId}
                trackId={trackId!}
                startWorkFlow={startWorkFlow ?? CreateCandidateWorkflowSteps.SELECT_PRODUCT}
                handleMeetingBooked={handleMeetingBooked}
                updateCandidateResume={updateCandidateResume}
            />}
        </div>
    )
};

type CandidateCardProps = {
    jobId: string;
    talentPoolProps?: TalentPoolProps;
    vettedProps?: VettedProps;
    preVettedProps?: PreVettedProps;
    interviewProps?: InterviewProps;
    borderColor?: string;
    showBadge?: boolean;
    expertId: string;
    isRejected?: boolean;
    isFinalized?: boolean;
    isVettedDisabled: boolean;
    trackId: string;
    candidateTrackId?: string;
    meetingBooked?: boolean;
    candidatePrivacy?: boolean;
    meetingSchedule?: string;
    allAnswerSubmitted?: boolean
    fraudInfo?: { fraud: boolean, fraudReason: string };
    handleShowMeetingDetails: (candidateId: string) => void;
    handleCreateCandidate: (workflowStep?: CreateCandidateWorkflowSteps) => void;
} & JobCandidate;

export const CandidateCard = ({
    attributes = [],
    secondaryAttributes,
    isVettedDisabled,
    reason,
    fullname,
    emailReminderCount,
    meetingCount,
    status,
    email,
    fastTrack,
    talentPoolProps,
    vettedProps,
    preVettedProps,
    interviewProps,
    candidateId,
    candidateTrackId,
    trackId,
    borderColor,
    showBadge,
    expertId,
    isRejected,
    isFinalized,
    meetingBooked,
    allAnswerSubmitted,
    fraudInfo,
    meetingStatus,
    meetingSchedule,
    handleShowMeetingDetails,
    candidatePrivacy,
    jobId,
    expertInfo,
    companyInfo,
    handleCreateCandidate,
    phoneNumber,
    candidateJobMappingSource,
    taskAssignedToYou,
    scoresData,
    resumeUrl
}: CandidateCardProps) => {
    const [showCapabilites, setShowCapabilites] = useState<boolean>(false);
    const [showReason, setShowReason] = useState<boolean>(false);
    const [showSendCustomReminder, setShowSendCustomReminder] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps>();
    const [showCandidateMoveOption, setShowCandidateMoveOption] = useState<any | null>(null);
    const [showMoreOption, setShowMoreOption] = useState<any | null>(null);
    const [sendEmail, setSendEmail] = useState<boolean>(true);
    const [addCommentPopover, setAddCommentPopover] = useState<any | null>(null);
    const [additionalInfoPopoverEl, setAdditionalInfoPopoverEl] = React.useState<any | null>(null);
    const [downloadResumePopover, setDownloadResumePopover] = React.useState<any | null>(null);
    const [uploadResumePopover, setUploadResumePopover] = React.useState<any | null>(null);
    const [selectedResumeUrl, setSelectedResumeUrl] = useState<string>(resumeUrl ?? "");
    const disableTalentPoolCard = talentPoolProps?.isTalentPool && fastTrack;
    const dispatch = useDispatch();
    const { changeCandidateStatus } = useJobDispatcher();
    const timeoutId = useRef<any | null>(null);
    const selectedMoreAction = useRef<string>('');

    useEffect(() => {
        if(resumeUrl) {
            setSelectedResumeUrl(resumeUrl);
        }
    }, [resumeUrl])

    const handleViewPerformance = async () => {
        if (candidateId !== '' && candidateTrackId !== '') {
            const response = await candidateService.getCandidateAuthorizationToken({ expertId, token: DEFAULT_TOKEN, candidateId });
            if (response.apiStatus === API_STATUSES.SUCCESS) {
                const { authorizationToken: token } = response.output;
                let urlParams = {
                    candidateId,
                    trackid: trackId,
                    candidateTrackId,
                    authorizationToken: token,
                    lpflowtype: "enroll",
                    isHiringManager: true,
                    [IS_SIMULATION_MODE]: true,
                }
                const url = buildUrlWithParams(BASE_PORTAL_URL + DASHBOARD_URL_CANDIDATE_APP, urlParams);
                window.open(url);
            } else {
                toast.error("Something went wrong");
            }
        }
    }

    const handleButtonClick = (successHandler: (candidateId: string, reason: string, fastTrack: boolean) => void, buttonText: string, showReason: boolean) => {
        const onReject = () => {
            setConfirmationProps({
                show: false,
            });
        };

        const onSuccess = (reason: string) => {
            successHandler(candidateId, reason || '', fastTrack);
            setConfirmationProps({
                show: false,
            });
        };

        const confirmationProps: ConfirmationProps = {
            onReject,
            onSuccess,
            buttonText,
            showReason,
            candidateName: fullname,
            show: false,
        };

        setConfirmationProps({
            ...confirmationProps,
            show: true
        });
    }

    const handleRemoveCandidate = (buttonText: string, showReason: boolean) => {
        const onReject = () => {
            setConfirmationProps({
                show: false,
            });
        };
        const onSuccess = () => {
            changeCandidateStatus({ candidateId, reason: "Removed by expert", jobId, status: 'REJECTED_CANDIDATE', fastTrack, sendEmail: true });
            setConfirmationProps({
                show: false,
            });
        };
        const confirmationProps: ConfirmationProps = {
            onReject,
            onSuccess,
            buttonText,
            showReason,
            candidateName: fullname,
            show: false,
        };

        setConfirmationProps({
            ...confirmationProps,
            show: true
        });
    }

    const completeMeetingBookingStep = () => {
        dispatch(setSelectedCandidateForBookMeetingProcess({
            candidateId, fullname, candidateTrackId, status, fastTrack, meetingStatus, email
        }));
        handleCreateCandidate(CreateCandidateWorkflowSteps.SELECT_PRODUCT);
    }

    const viewMeetingBookingDetails = () => {
        handleShowMeetingDetails(candidateId);
    }

    const handleMoveCandidate = (e: any) => {
        setShowCandidateMoveOption(e.currentTarget);
    }

    const changeCandidateStep = (newCardValue: STATUS_TYPE, newCardTitle: string) => {
        setShowCandidateMoveOption(null);
        changeCandidateStatus({ candidateId, reason: "State changed by expert", jobId, status: newCardValue, fastTrack, sendEmail: sendEmail });
        setSendEmail(true); // resetting send email after candidate was moved
        // dispatch(changeCandidateState({ newState: cardValue, oldState: status, candidateId, newStateTitle: cardTitle }));
    }

    const meetingPendingForBooking = () => {
        return fastTrack === true && meetingSchedule === MeetingScheduleBy.RECRUITER && meetingStatus === false;
    }

    const handleMouseEnterPopover = (cb: Function, timeOutTime: number) => {
        clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => {
            cb();
        }, timeOutTime);
    }

    const handleMouseLeavePopover = () => {
        clearTimeout(timeoutId.current);
    }

    const handleIncrementReminderCount = () => {
        dispatch(incrementReminderCount({
            candidateState: status,
            candidateId
        }))
    }

    const getActionButtons = () => {
        return (
            <>
                {talentPoolProps && (
                    (meetingBooked === false || allAnswerSubmitted === false) ?
                        <FastTrackButtonContainer className='d-flex align-items-center'>
                            <CandidatesButton mb={0} mt={10} variant='primary' width='100%'
                                onClick={() => handleButtonClick(talentPoolProps.handleSendReminder, messages.SEND_REMINDER, false)}>
                                {messages.SEND_REMINDER}
                            </CandidatesButton>
                            <InfoOutline data-tip data-for={'tooltip-send-reminder-to-candidate'} />
                            <ReactTooltip id={'tooltip-send-reminder-to-candidate'} type="dark">
                                Send reminder to candidate
                            </ReactTooltip>
                        </FastTrackButtonContainer>
                        : <Label className='my-2'>
                            {allAnswerSubmitted === true && <LightSpan><b>Candidate has submitted all the questions</b></LightSpan>}
                        </Label>
                )}
                {meetingPendingForBooking() &&
                    <FastTrackButtonContainer className='d-flex align-items-center'>
                        <CandidatesButton mt={10} mb={0} variant='secondary'
                            onClick={completeMeetingBookingStep}>
                            {'Complete meeting booking'}
                        </CandidatesButton>
                    </FastTrackButtonContainer>
                }
                {(meetingStatus === true) &&
                    <FastTrackButtonContainer className='d-flex align-items-center'>
                        <CandidatesButton mt={10} mb={0} variant='primary'
                            onClick={viewMeetingBookingDetails}>
                            {'View meeting details' + `${meetingCount && meetingCount > 0 ? ` (${meetingCount} ${meetingCount > 1 ? 'meetings' : 'meeting'})` : ''}`}
                        </CandidatesButton>
                    </FastTrackButtonContainer>
                }
            </>
        )
    }

    const getActionLinks = () => {
        return (<>
            {!talentPoolProps?.isTalentPool && (
                <div style={{ width: '100%' }}>
                    <CapabilityLink svgh={15}>
                        <div onClick={() => setShowCapabilites(true)}>
                            View capabilities
                            <ChevronDoubleRight />
                        </div>
                    </CapabilityLink>
                </div>
            )}
            {(isFinalized || isRejected) && reason && (
                <div style={{ width: '100%' }}>
                    <CapabilityLink mt={4} svgh={22}>
                        <div onClick={() => setShowReason(true)}>
                            View evaluation feedback
                            <PersonFeedback />
                        </div>
                    </CapabilityLink>
                </div>
            )}
            {
                <W100 disabled={isVettedDisabled || (candidatePrivacy && preVettedProps?.isPreVetted)}>
                    <CapabilityLink svgh={15} mt={4}>
                        <div onClick={() => handleViewPerformance()}>
                            {messages.VIEW_CANDIDATE_PERFORMANCE}
                            <ChevronDoubleRight />
                        </div>
                    </CapabilityLink>
                </W100>
            }
        </>)
    }

    const getActionIcons = () => {
        const tooltipId = 'action-buttons-tooltip';

        return (
            <ActionIconsWrapper>
                {talentPoolProps?.isTalentPool && !fastTrack && (
                    <IconButton size="small" style={{ backgroundColor: '#315cd5' }}
                        data-tip={`${messages.FAST_TRACK}: Get this candidate evaluated by our FAANG Experts`}
                        data-for={tooltipId}
                        onClick={() => handleButtonClick(talentPoolProps.handleTriggerFastTrack, messages.FAST_TRACK, false)}
                    >
                        <DoubleArrow />
                    </IconButton>
                )}
                {preVettedProps?.isPreVetted && (
                    <IconButton size="small" disabled={isVettedDisabled} style={{ backgroundColor: '#315cd5' }}
                        data-tip={messages.PRE_VETTED_CANDIDATE}
                        data-for={tooltipId}
                        onClick={() => handleButtonClick(preVettedProps?.moveToVetted!, messages.PRE_VETTED_CANDIDATE, false)}
                    >
                        <Person />
                    </IconButton>
                )}
                {vettedProps?.isVetted && (
                    <IconButton size="small" disabled={isVettedDisabled} style={{ backgroundColor: '#315cd5' }}
                        data-tip={messages.SEND_INTERVIEW_REQUEST}
                        data-for={tooltipId}
                        onClick={() => handleButtonClick(vettedProps?.sendInterviewRequest, messages.SEND_INTERVIEW_REQUEST, false)}
                    >
                        <MailSend />
                    </IconButton>
                )}
                {interviewProps?.isInterview && (
                    <>
                        <IconButton size="small" style={{ backgroundColor: '#315cd5' }}
                            data-tip={messages.SEND_REMINDER}
                            data-for={tooltipId}
                            onClick={() => handleButtonClick(interviewProps?.sendReminder!, messages.SEND_REMINDER, false)}
                        >
                            <BellRing />
                        </IconButton>
                        <IconButton size="small" style={{ outline: `1px solid ${theme.colors.REJECT}` }}
                            data-tip={messages.REJECT}
                            data-for={tooltipId}
                            onClick={() => handleButtonClick(interviewProps?.onReject, messages.REJECT, true)}
                        >
                            <Cross
                                style={{ color: theme.colors.REJECT, width: secondaryBtnWidth }}
                            />
                        </IconButton>
                        <IconButton size="small" style={{ outline: `1px solid ${theme.colors.FINALISE}` }}
                            data-tip={messages.FINALISE}
                            data-for={tooltipId}
                            onClick={() => handleButtonClick(interviewProps?.onFinalise, messages.FINALISE, true)}
                        >
                            <Check
                                style={{ color: theme.colors.FINALISE, width: secondaryBtnWidth }}
                            />
                        </IconButton>
                    </>
                )}
                <Badge badgeContent={""} color="info" variant='dot' invisible={!taskAssignedToYou}>
                    <IconButton size="small" style={{ outline: `1px solid ${theme.colors.ADD_COMMENT}` }}
                        data-tip={messages.ADD_COMMENT}
                        data-for={tooltipId}
                        onMouseEnter={(e) => handleMouseEnterPopover(() => setAddCommentPopover(e.target), 200)}
                        onMouseLeave={(e) => handleMouseLeavePopover()}
                    >
                        <AddComment
                            style={{ color: theme.colors.ADD_COMMENT, width: secondaryBtnWidth }}
                        />
                    </IconButton>
                </Badge>
                <IconButton size="small" style={{ outline: `1px solid ${theme.colors.MOVE_CANDIDATE}` }}
                    data-tip={messages.MOVE_CANDIDATE}
                    data-for={tooltipId}
                    onClick={handleMoveCandidate}
                >
                    <ArrowsMove
                        style={{ color: theme.colors.MOVE_CANDIDATE, width: secondaryBtnWidth }}
                    />
                </IconButton>

                <IconButton size="small" style={{ backgroundColor: theme.colors.MORE_OPTION_BUTTON }}
                    data-tip={`More`}
                    data-for={tooltipId}
                    onClick={(e) => setShowMoreOption(e.currentTarget)}>
                    <MoreVerticalOutline />
                </IconButton>
                <ReactTooltip id={tooltipId} type="dark" place='bottom' effect='solid' />
            </ActionIconsWrapper>
        )
    }

    const candidateInfo = (
        <div id={fullname + email} className='p-2 overflow-auto'>
            <div style={{ width: 'max-content', maxWidth: '30rem', maxHeight: '40rem' }}>
                {!candidatePrivacy && phoneNumber && phoneNumber.countryCode && phoneNumber.phoneNumber &&
                    <div className='mb-2'>
                        <span style={{ color: '#6F6F6F' }}>Phone Number:&nbsp;</span>
                        <span className='font-weight-bold'>{phoneNumber.countryCode}-{phoneNumber.phoneNumber}</span>
                    </div>}
                {expertInfo && <div className='mb-2'>
                    <span style={{ color: '#6F6F6F' }}>Owner:&nbsp;</span>
                    <span className='font-weight-bold'>{expertInfo.fullname}</span>
                </div>}
                {companyInfo?.companyName && <div className='mb-2'>
                    <div className='d-flex align-items-center'>
                        <span style={{ color: '#6F6F6F' }}>Company:&nbsp;</span>
                        {companyInfo.companyLogo && <img src={companyInfo.companyLogo} alt="" className="rounded img-fluid mr-2" width="20" />}
                        <span className='font-weight-bold'>{companyInfo.companyName}</span>
                    </div>
                </div>}
                {emailReminderCount !== null && emailReminderCount !== undefined && <div className='mb-2'>
                    <span style={{ color: '#6F6F6F' }}>Total Reminders Sent:&nbsp;</span>
                    <span className='font-weight-bold'>{
                        emailReminderCount
                    }</span>
                </div>}
                {candidateJobMappingSource && <div className='mb-2'>
                    <span style={{ color: '#6F6F6F' }}>Candidate Origin:&nbsp;</span>
                    <span className='font-weight-bold'>{candidateJobMappingSource === "INBOUND" ? "Applied through website" : "Was added by expert"}</span>
                </div>}
                {scoresData?.resumeDetails?.candidateExperience && scoresData?.resumeDetails?.candidateExperience.length > 0 &&
                    <div className='mb-2'>
                        <span style={{ color: '#6F6F6F' }}>Experience:&nbsp;</span>
                        <TableContainer component={Paper} sx={{ maxHeight: "10rem" }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Company</b></TableCell>
                                        <TableCell><b>Tier</b></TableCell>
                                        <TableCell><b>Title</b></TableCell>
                                        <TableCell><b>Duration</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {scoresData?.resumeDetails?.candidateExperience?.map((data, idx) => {
                                        return (<TableRow
                                            key={data.company + idx}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell> {data.company}</TableCell>
                                            <TableCell> {data.tier}</TableCell>
                                            <TableCell> {data.title}</TableCell>
                                            <TableCell> {data.duration}</TableCell>
                                        </TableRow>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>}
                {scoresData?.resumeDetails?.careerProgression?.progress && <div className='mb-2'>
                    <span style={{ color: '#6F6F6F' }}>Career Progress:&nbsp;</span>
                    <span className='font-weight-bold'>{scoresData?.resumeDetails?.careerProgression?.progress}</span>
                </div>}
                {scoresData?.resumeDetails?.careerProgression?.details && <div className='mb-2'>
                    <span style={{ color: '#6F6F6F' }}>Career Details:&nbsp;</span>
                    <span className='font-weight-bold'>{scoresData?.resumeDetails?.careerProgression?.details}</span>
                </div>}
                {scoresData?.resumeDetails?.educationDetails && scoresData?.resumeDetails?.educationDetails.length > 0 &&
                    <div className='mb-2'>
                        <span style={{ color: '#6F6F6F' }}>Education Details:&nbsp;</span>
                        <TableContainer component={Paper} sx={{ maxHeight: "10rem" }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>University</b></TableCell>
                                        <TableCell><b>Degree</b></TableCell>
                                        <TableCell><b>Duration</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {scoresData?.resumeDetails?.educationDetails?.map((data, idx) => {
                                        return (<TableRow
                                            key={data.degree + idx}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell> {data.university}</TableCell>
                                            <TableCell> {data.degree}</TableCell>
                                            <TableCell> {data.duration}</TableCell>
                                        </TableRow>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>}
            </div>
        </div>
    )

    return (
        <>
            <CardWrapper borderTop={borderColor} className='mr-0' style={{ padding: '20px 12px' }}>
                <CardSubwrapper disable={disableTalentPoolCard}>
                    {showBadge &&
                        <LogoImage style={{ position: 'absolute', top: '4px', right: '0', width: '20px' }} src={StarBadgeIcon} />
                    }
                    {(vettedProps?.isVetted || talentPoolProps?.isTalentPool) && fastTrack && (
                        <FastTracked data-tip data-for={candidateId}>Fast-Forwarded</FastTracked>
                    )}
                    {isRejected && fraudInfo?.fraud && (
                        <RejectedTracked data-tip data-for={candidateId}>Fraud-Candidate</RejectedTracked>
                    )}
                    <div style={{ fontSize: '16px' }} className='d-flex mt-1'>
                        <div style={{ minWidth: '3.2rem' }}>
                            <span style={{ color: '#6F6F6F' }}>Name:&nbsp;</span>
                        </div>
                        <div className='d-flex justify-content-start align-items-between'>
                            <span className='font-weight-bold'>{
                                (candidatePrivacy && (preVettedProps?.isPreVetted || vettedProps?.isVetted)) ? '******' : fullname ? fullname : '******'
                            }</span>
                            <InfoCircleFill
                                className='info-icon'
                                onMouseEnter={(e) => handleMouseEnterPopover(() => setAdditionalInfoPopoverEl(e.target), 400)}
                                onMouseLeave={() => handleMouseLeavePopover()}
                            />
                            <Popover
                                open={Boolean(additionalInfoPopoverEl)}
                                anchorEl={additionalInfoPopoverEl}
                                onClose={() => setAdditionalInfoPopoverEl(null)}
                                anchorOrigin={{
                                    vertical: "center",
                                    horizontal: 'left',
                                }}
                            >
                                {candidateInfo}
                            </Popover>
                        </div>
                    </div>
                    {!candidatePrivacy && email && <div style={{ fontSize: '16px' }} className='d-flex mt-1'>
                        <span style={{ color: '#6F6F6F', minWidth: '3.2rem' }}>Email:&nbsp;</span>
                        <span className='font-weight-bold'>{email}</span>
                    </div>}
                    {scoresData?.resumeDetails?.companyTier && <div style={{ fontSize: '16px' }} className='d-flex mt-1'>
                        <span style={{ color: '#6F6F6F', minWidth: '3.2rem' }}>Company Tier:&nbsp;</span>
                        <span className='font-weight-bold'>{scoresData?.resumeDetails?.companyTier}</span>
                    </div>}
                    {scoresData?.jobMatchingScore?.similarityScore && isNumber(scoresData?.jobMatchingScore?.similarityScore) && <div style={{ fontSize: '16px' }} className='d-flex mt-1'>
                        <span style={{ color: '#6F6F6F', minWidth: '3.2rem' }}>Job Matching Score:&nbsp;</span>
                        <span className='font-weight-bold'>{round(scoresData.jobMatchingScore.similarityScore, 2)}</span>
                    </div>}
                    {scoresData?.jobMatchingScore?.similarityScore && isNumber(scoresData?.resumeDetails?.maxScoreUniversity) && <div style={{ fontSize: '16px' }} className='d-flex mt-1'>
                        <span style={{ color: '#6F6F6F', minWidth: '3.2rem' }}>Max Score University:&nbsp;</span>
                        <span className='font-weight-bold'>{round(scoresData.resumeDetails.maxScoreUniversity, 2)}</span>
                    </div>}

                    {getActionButtons()}
                    {getActionLinks()}
                    {getActionIcons()}

                    {showCapabilites && (
                        <CapabilitiesModal
                            showScore
                            name={isVettedDisabled ? undefined : fullname}
                            attributes={attributes}
                            secondaryAttributes={secondaryAttributes}
                            show={showCapabilites}
                            handleClose={() => setShowCapabilites(false)}
                            dialogClassName={`candidates-capabilities-dialog-${isMobile && 'mobile'}`}
                        />
                    )}
                    {showReason && (
                        <InformationModal
                            show={showReason}
                            headerMessage={'Feedback'}
                            bodyMessage={reason!}
                            fullname={fullname}
                            handleClose={() => setShowReason(false)}
                        />
                    )}
                    <Popover
                        open={Boolean(addCommentPopover)}
                        anchorEl={addCommentPopover}
                        onClose={() => setAddCommentPopover(null)}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: 'left',
                        }}
                    >
                        <AddCommentModal
                            expertId={expertId}
                            candidateId={candidateId}
                            jobId={jobId}
                            ownerExpert={expertInfo}
                        />
                    </Popover>
                    <Popover
                        open={Boolean(downloadResumePopover)}
                        anchorEl={downloadResumePopover}
                        onClose={() => {
                            setDownloadResumePopover(null);
                            setShowMoreOption(null);
                        }}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: 'right',
                        }}>
                        <DownloadResume
                            trackId={trackId}
                            candidateId={candidateId}
                            selectedResumeUrl={selectedResumeUrl}
                        />
                    </Popover>
                    <Popover
                        open={Boolean(uploadResumePopover)}
                        anchorEl={uploadResumePopover}
                        onClose={() => {
                            setUploadResumePopover(null);
                            setShowMoreOption(null);
                        }}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: 'right',
                        }}>
                        <UploadResume
                            trackId={trackId}
                            candidateId={candidateId}
                            expertId={expertId}
                            selectedResumeUrl={selectedResumeUrl}
                            setSelectedResumeUrl={setSelectedResumeUrl}
                        />
                    </Popover>
                    {showSendCustomReminder &&
                        <SendCustomReminder
                            show={showSendCustomReminder}
                            handleClose={() => setShowSendCustomReminder(false)}
                            expertId={expertId}
                            candidateId={candidateId}
                            candidateTrackId={candidateTrackId || ""}
                            jobId={jobId}
                            trackId={trackId}
                            incrementReminderCount={handleIncrementReminderCount}
                        />}
                    {confirmationProps?.show && (
                        <ConfirmationModal {...confirmationProps} />
                    )}
                </CardSubwrapper>
            </CardWrapper>
            {disableTalentPoolCard && (
                <ReactTooltip id={candidateId} type="dark">
                    The fast forwarded candidate is yet to be evaluated
                </ReactTooltip>
            )}
            {isRejected && fraudInfo?.fraud && (
                <ReactTooltip id={candidateId} type="dark" place='left' >
                    {fraudInfo?.fraudReason}
                </ReactTooltip>
            )}
            {isVettedDisabled && (
                <ReactTooltip id={candidateId} type="dark">
                    Candidate has not accepted the candidature
                </ReactTooltip>
            )}
            <Menu
                anchorEl={showCandidateMoveOption}
                open={Boolean(showCandidateMoveOption)}
                onClose={() => setShowCandidateMoveOption(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem disabled={true} >Move candidate to new step</MenuItem>
                <div className='pl-3'>
                    <FormControlLabel control={<Checkbox defaultChecked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)} />} label="Send Email?" />
                </div>
                {Object.keys(cardsTitle).map((val, idx) => {
                    if (cardsTitle[val].value === status || cardsTitle[val].secondaryValue === status) {
                        return null;
                    }
                    return (
                        <MenuItem
                            onClick={() => changeCandidateStep(cardsTitle[val].value, cardsTitle[val].name)}
                            key={idx + cardsTitle[val].name}
                        >
                            {cardsTitle[val].name}
                        </MenuItem>
                    )
                })}
            </Menu>
            <Menu
                anchorEl={showMoreOption}
                open={Boolean(showMoreOption)}
                onClose={() => setShowMoreOption(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClick={(e) => {
                    if (selectedMoreAction.current !== messages.DOWNLOAD_RESUME && selectedMoreAction.current !== messages.UPLOAD_RESUME) {
                        setShowMoreOption(null);
                    }
                }}
            >
                {!meetingPendingForBooking() && !isRejected && <MenuItem
                    onClick={() => {
                        selectedMoreAction.current = messages.SCHEDULE_MEETING;
                        completeMeetingBookingStep()
                    }}
                >
                    <CalendarPlusFill
                        style={{ color: theme.colors.PRIMARY_01, width: secondaryBtnWidth }}
                        className='mr-2'
                    />
                    {messages.SCHEDULE_MEETING}
                </MenuItem>}
                <MenuItem
                    onClick={() => {
                        selectedMoreAction.current = messages.SEND_CUSTOM_REMINDER;
                        setShowSendCustomReminder(true)
                    }}
                >
                    <AlarmFill
                        style={{ color: theme.colors.PRIMARY_01, width: secondaryBtnWidth }}
                        className='mr-2'
                    />
                    {messages.SEND_CUSTOM_REMINDER}
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        selectedMoreAction.current = messages.DOWNLOAD_RESUME;
                        setDownloadResumePopover(e.currentTarget);
                    }}
                >
                    <CloudDownloadFill
                        style={{ color: theme.colors.PRIMARY_01, width: secondaryBtnWidth }}
                        className='mr-2'
                    />
                    {messages.DOWNLOAD_RESUME}
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        selectedMoreAction.current = messages.UPLOAD_RESUME;
                        setUploadResumePopover(e.currentTarget);
                    }}
                >
                    <CloudUploadFill
                        style={{ color: theme.colors.PRIMARY_01, width: secondaryBtnWidth }}
                        className='mr-2'
                    />
                    {messages.UPLOAD_RESUME}
                </MenuItem>
                {!isRejected && <MenuItem
                    onClick={() => handleRemoveCandidate(messages.REMOVE_CANDIDATE, false)}
                >
                    <Delete
                        style={{ color: theme.colors.PRIMARY_01, width: secondaryBtnWidth }}
                        className='mr-2'
                    />
                    {messages.REMOVE_CANDIDATE}
                </MenuItem>}
            </Menu>
        </>
    )
}

type NavProps = {
    onBackClick: () => void;
    jobAccess: string;
};

const Nav = ({ onBackClick, jobAccess }: NavProps) => {
    return (
        <NavButtonContainer>
            <NavButton onClick={onBackClick}>
                <KeyboardBackspace />
                Back
            </NavButton>
            <NavButtonLabel>
                {jobAccess === JOB_ACCESS_CONSTANT.PUBLIC && <>
                    <Globe
                        color="forestgreen"
                        data-tip="Public Job"
                        data-for="job-access-info-tooltip-public"
                    />
                    <span>Public Job</span>
                </>}
                {jobAccess === JOB_ACCESS_CONSTANT.PRIVATE && <>
                    <Lock
                        color="gold"
                        data-tip="Private Job"
                        data-for="job-access-info-tooltip-private"
                    />
                    <span>Private Job</span>
                </>}
            </NavButtonLabel>
        </NavButtonContainer>
    );
};