import { Box, Divider, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { CoachEvaluation } from "types/jobPipeline";
import { Accordion, AccordionSummary, AccordionDetails, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import styled from "styled-components";


const Feedbacktext = styled.div`
 font-style: italic;
    background-color: #ffff99; 
    padding: 2px 5px;
    border-radius: 3px;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 0.9rem;
`;

interface CandidateCoachEvaluationProps {
  coachEvaluation: CoachEvaluation;
}

const CandidateCoachEvaluationComponent: React.FC<
  CandidateCoachEvaluationProps
> = ({ coachEvaluation }) => {

  if (coachEvaluation?.coachEvalScore <= 0 && (!coachEvaluation?.meetingDetails || coachEvaluation?.meetingDetails.length <= 0)){
    return (
      <Typography variant="body2" color="textSecondary">
          There is no coach evaluation in progress.
      </Typography>
    )
  }


  return (
    <>
   
      <Box>
        <Box display="flex" alignItems="center" mb={1}>
          {coachEvaluation?.profileImgUrl ? (
            <img
              src={coachEvaluation.profileImgUrl}
              alt={coachEvaluation?.expertName}
              style={{
                borderRadius: "50%",
                marginRight: "10px",
                width: "40px",
                height: "40px",
              }}
            />
          ) : (
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#3f51b5",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {coachEvaluation?.expertName
                ?.split(" ")
                .map((n) => n[0])
                .join("")}
            </Box>
          )}
          <Box>
            <Typography variant="body1">
              {coachEvaluation?.expertName}
              {coachEvaluation?.coachEvalScore && (
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    padding: "2px 8px",
                    borderRadius: "8px",
                    backgroundColor: "#f0e68c",
                    color: "#333",
                    fontSize: "12px",
                    fontWeight: "bold",
                    ml: 1,
                  }}
                >
                  Coach Eval Score: {coachEvaluation?.coachEvalScore.toFixed(1)}
                </Box>
              )}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {moment(coachEvaluation?.completedOn).format("MMMM D, YYYY")}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="body2"
          mb={2}
          dangerouslySetInnerHTML={{ __html: coachEvaluation.feedback || "" }}
        />
        <Divider sx={{ my: 2 }} />
        {coachEvaluation?.meetingDetails?.map((meeting: any, index: number) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Link
                to={`/meeting?meetingDetailId=${meeting?.meetingDetailId}`}
                target="_blank"
              >
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  {meeting?.meetingTitle || "N/A"}
                </Typography>
              </Link>

              <Link
                to={`/meeting?meetingDetailId=${meeting?.meetingDetailId}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "14px", fontWeight: "bold" }}
                title={"View Details"}
              >
                View Details
              </Link>
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Typography
                sx={{ fontSize: ".8rem" }}
              >{meeting?.meetingPurpose}</Typography>
              {meeting?.meetingStatus === "SCHEDULED" ? (
                <Typography sx={{ fontSize: ".8rem" }}>
                  Scheduled at:{" "}
                  {new Date(meeting?.meetingTime)
                    .toLocaleString()
                    .replace(":00", "")}
                </Typography>
              ) : meeting?.meetingStatus === "COMPLETED" ? (
                <Typography
                  sx={{
                    fontSize: ".8rem",
                    color: "#4caf50",
                    fontWeight: "bold",
                  }}
                >
                  Completed
                </Typography>
              ) : (
                <Typography sx={{ fontSize: ".8rem", color: "red" }}>
                  {meeting?.meetingStatus}
                </Typography>
              )}
            </Box>
            <Box>
            {meeting?.overallFeedback?.feedback && (
              <Feedbacktext>{meeting.overallFeedback.feedback}</Feedbacktext>
            )}
            </Box>
            {meeting?.feedbackDetails && meeting?.feedbackDetails.length &&
                meeting?.feedbackDetails?.map(
                  (feedback: any, feedbackIndex: number) => (
                    <Accordion key={feedbackIndex}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          sx={{ fontSize: ".8rem", fontWeight: "bold" }}
                        >
                          {feedbackIndex + 1}. {feedback?.questionTitle}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography sx={{ fontSize: ".8rem", color: "black" }}>
                          {parse(feedback?.feedback)}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )
              ) || (
                <Typography sx={{ fontSize: ".8rem", color: "gray" }}>
                  {meeting?.meetingStatus !== "SCHEDULED"
                    ? "No Feedback Available"
                    : ""}
                </Typography>
              )
            }
          </Box>
        ))}
      </Box>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default CandidateCoachEvaluationComponent;
