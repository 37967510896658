import { UserReceived } from "@styled-icons/remix-fill/UserReceived";
import { Paginator } from "components/Paginator";
import { debounce, isNumber } from "lodash";
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { IconContainer } from '../../../components/Common/IconContainer/IconContainer';
import { SearchButton } from '../../../components/SearchButton';
import { RootState } from '../../../store';
import { DefaultPaginationCount, getLocalDate, MeetingStatusNew, ReviewStatus } from '../../../utilities/constants';
import useCandidate from '../../Candidate/Candidate.utils';
import { Candidates } from '../../Candidate/ICandidate';
import { SubmissionContent } from '../../Feedback/TabContent/Submissions/Submissions.styles';
import useMeeting, { getMeetingValue } from '../../Meeting/Meeting.utils';
import { GetMeetingRequest } from "../IMeeting";
import { IFocussedModule } from "../meetingTypes";
import CandidateNotesComponent from './components/CandidateNotesComponent';
import CandidateReviewComponent from "./components/CandidateReviewComponent";
import { Collapsible } from "./components/Collapsible";
import ExpertFeedbackComponent from './components/ExpertFeedbackComponent';
import { MeetingModulesWrapper } from "./components/MeetingModulesWrapper";
import { RejectedTracked } from "pages/B2B/Jobs/Jobs.styled";
import ReactTooltip from "react-tooltip";
import { StyledMuiAutoComplete } from "./UpcomingMeetings";
import { TextField } from "@mui/material";
import ZoomMeetingModal from "./ZoomMeetingModal";

const CompletedMeetingWrapper = styled.div`
  margin: 10px;
`;

const Main = styled.div<any>`
  .meeting-details {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    position: relative;

    & .detail {
        text-align: left;
        margin-bottom: 1rem;
        width:25%;
    }

    & .detail:nth-of-type(4n) {
        margin-right: 0;
    }

    & .detail:nth-of-type(4n+1) {
        margin-left: 0;
    }
    & .textWrapper {
      overflow-wrap: break-word;
    }
  }
  .meeting_type{
    display: flex;
    font-size: 17px;
    font-weight: bold;
    color: #000;
}
`;

interface ICompletedMeetings {

}

const CompletedMeetings = ({ }: ICompletedMeetings) => {
    const history = useHistory();
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);
    const todayDate = moment().subtract(1, 'd').format('YYYY-MM-DD');
    const [
        {
            getAllCandidatesAssociatedByExpert,
            feedbackCandidates,
            meetingCandidates,
            loading
        },
    ] = useCandidate();

    const allCandidates = [...meetingCandidates, ...feedbackCandidates];

    const uniqueCandidates = Array.from(
      new Map(allCandidates.map(candidate => [candidate.candidateEmail, candidate])).values()
    );

    const [
        {
            meetingLoading,
            meetings,
            resetMeetingList,
            getAllMeetingList,
            skipCount,
            totalCount,
            setSkipCountAction,
        },
    ] = useMeeting();


    const [candidateId, setCandidateId] = useState('');
    const [startDate, setStartDate] = useState(moment().subtract(2, 'months').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD'));
    const [reviewStatus, setReviewStatus] = useState<string>();
    const [zoomRecordingInfo, setZoomRecordingInfo] = useState<boolean>(false);
    const [selectedMeeting, setSelectedMeeting] = useState<any>(null);
    const [startDateTimeZone, setStartDateTimeZone] = useState(todayDate);
    const [endDateTimeZone, setEndDateTimeZone] = useState(todayDate);
    const debounceCandidateData = useCallback(debounce(
        (filterString: string) => { getAllCandidatesAssociatedByExpert(filterString) },
        400), [expertId]);

    useEffect(() => {
        resetMeetingList();
        getResult(getSearchObj());
    }, []);

    const setFormData = (e: any, fieldName: string) => {
        switch (fieldName) {
            case 'candidateId':
                setCandidateId(e?.candidateId!);
                break;
            case 'startDate':
                setStartDate(e.target.value);
                if (e.target.value !== '' && endDate !== '') {
                    let start1 = moment(e.target.value);
                    let end1 = moment(endDate);
                    let diff = end1.diff(start1, 'days');
                    if (diff < 0) {
                        setEndDate(e.target.value);
                    }
                }
                break;
            case 'endDate':
                setEndDate(e.target.value);
                break;

    
            case 'startDateTimeZone':
            if (e.target.value) {
                setStartDateTimeZone(
                moment.tz(e.target.value).utc().format()
                );
            } else {
                setStartDateTimeZone('');
            }
            break;

            case 'endDateTimeZone':
                if (e.target.value) {
                    setEndDateTimeZone(
                    moment.tz(e.target.value).utc().format()
                    );
                } else {
                    setEndDateTimeZone('');
                }
                break; 
    
            case 'reviewStatus':
                setReviewStatus(e.target.value);
                break;
        }
    }

    const getFileNameFromURL = (url: string) => {
        if (url) {
            var m = url.split('/');
            if (m && m.length > 1) {
                return decodeURIComponent(m[m.length - 1]);
            }
        }
        return "";
    }

    const handleMeetingNotes = (meeting: any) => {
        history.push(`meeting?meetingDetailId=${meeting.meetingDetailId}`)
    }

    const getResult = (searchObj: GetMeetingRequest) => {
        getAllMeetingList(searchObj);
    }

    const handlePaginationAction = (skipCount: number) => {
        setSkipCountAction(skipCount);
        getResult(getSearchObj(skipCount));
    }

    const getSearchObj = (customSkipCount?: number) => {
        customSkipCount = isNumber(customSkipCount) ? customSkipCount : skipCount;
        const searchObj = {
            meetingType: 'CUSTOM_DATE',
            expertId: expertId,
            candidateId: candidateId,
            startDate: '',
            endDate: '',
            startDateTimeZone: {},
            endDateTimeZone: {},
            meetingStatus: [MeetingStatusNew.CLOSED, MeetingStatusNew.COMPLETED],
            reviewStatus,
            count: DefaultPaginationCount,
            skipCount: customSkipCount
        };
        if (startDate !== '') {
            searchObj['startDate'] = moment(startDate).format("MM/DD/YYYY");
             searchObj['startDateTimeZone'] = {
                    dateTime : moment.tz(startDate).utc().format(),
                    TimeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
                  }
        }
        if (endDate !== '') {
            searchObj['endDate'] = moment(endDate).format("MM/DD/YYYY");
              searchObj['endDateTimeZone'] = {
                    dateTime : moment.tz(endDate).utc().format(),
                    TimeZone : Intl.DateTimeFormat().resolvedOptions().timeZone
                  }
        }
        return searchObj;
    }

    const renderMeetingModules = (label: string, focussedModules: IFocussedModule[]) => {
        return <MeetingModulesWrapper className="mb-3">
            <div><b>{label}</b></div>
            <div className="overflow-auto" style={{ height: "80%" }}>
                {focussedModules.map(cat => cat.children?.map(subcat => subcat.children?.map(cap => <span className='w-100 d-flex mb-1 border-bottom'>{cat.entityTitle + " | " + subcat.entityTitle + " | " + cap.entityTitle}</span>)))}
            </div>
        </MeetingModulesWrapper>
    }

    return (
        <CompletedMeetingWrapper>
            <Form
                style={{ padding: '10px' }}
            >
                <Row className="mt-2 d-flex pl-0 mr-0 ml-0 align-items-center">
                    <Col md={9} lg={9} sm={12} className="d-flex pl-0">
                        <Col className="mr-0 pr-0" md={3} lg={3} sm={10}>
                            <label>Select Candidate</label>
                            <StyledMuiAutoComplete
                                componentName={'candidateId'}
                                options={uniqueCandidates}
                                onChange={(e: any, value) => {
                                    setFormData(value, 'candidateId');
                                }}
                                getOptionLabel={(candidate: any) =>
                                    `${candidate.candidateName} (${candidate.candidateEmail}) (${candidate.marketName})`
                                }
                                loading={loading}
                                noOptionsText={'Type something to search...'}
                                renderInput={(params: any) =>
                                    <TextField
                                        {...params}
                                        placeholder={loading ? "Loading..." : "Select Candidate"}
                                        variant="outlined"
                                        onChange={(e) => {
                                            debounceCandidateData(e.target.value)
                                        }} />}
                            />
                        </Col>

                        <Col className="mr-0 pr-0" md={3} lg={3} sm={10}>
                            <label>Start Date</label>
                            <input type="date" max={todayDate} name="startDate" onChange={(e) => setFormData(e, 'startDate')} value={startDate} placeholder='Start Date' className="form-control" />
                        </Col>

                        <Col className="mr-0 pr-0" md={3} lg={3} sm={10}>
                            <label>End Date</label>
                            <input type="date" max={todayDate} disabled={startDate == ''} min={startDate} name="endDate" onChange={(e) => setFormData(e, 'endDate')} value={endDate} placeholder='Start Date' className="form-control" />
                        </Col>
                        <Col className="mr-0 pr-0" md={3} lg={3} sm={10}>
                            <label>Meeting Review</label>
                            <select name="reviewStatus" value={reviewStatus} onChange={(e) => setFormData(e, 'reviewStatus')} className="form-control">
                                <option value={undefined}>All</option>
                                <option value={ReviewStatus.expertViewedReview}>{'Meeting with seen reviews'}</option>
                                <option value={ReviewStatus.candidateGivesReview}>{'Meeting with unseen reviews'}</option>
                            </select>
                        </Col>
                        <Col md={3} lg={3} sm={10}>
                            <label>&nbsp;</label>
                            <SearchButton
                                type="button"
                                onClick={() => {
                                    setSkipCountAction(0);
                                    getResult(getSearchObj(0))
                                }}
                                style={{ width: '200px',position:"absolute",top:"33px" }}
                            >
                                {(meetingLoading) ? 'Loading...' : 'Show Meetings'}
                            </SearchButton>
                        </Col>

                    </Col>
                </Row>
            </Form>

            <Main>
                {totalCount > DefaultPaginationCount && meetings?.length != 0 && !meetingLoading && <Paginator
                    count={DefaultPaginationCount}
                    total={totalCount}
                    skipcount={skipCount}
                    onAction={handlePaginationAction}
                />}
                {meetings.map((meeting, ind) => (
                    <div className="d-flex">
                        {meeting.candidateFeedback && meeting.reviewStatus !== ReviewStatus.expertViewedReview && <IconContainer style={{ marginLeft: "-23px" }} icon={UserReceived} tooltip="Review Received" />}
                        <SubmissionContent key={'meeting' + ind} id={'meeting' + meeting.id}>
                            <p className='meeting_type'>
                                {meeting?.serviceType == "MOCKUP_INTERVIEW" && "Mock Interview" ||
                                    meeting?.serviceType == "COACHING" && "Coaching" ||
                                    meeting?.serviceType == "FREE_MEETING" && "Free Meeting" ||
                                    meeting?.serviceType == "CAREER_CONSULTANCY" && "Career Consultancy" ||
                                    meeting?.serviceType == "JOB_CONSULTANCY" && "Job Consultancy"
                                }
                            </p>
                            <div className="meeting-details">
                                <Collapsible
                                    title={
                                        <>
                                            <div className="detail">
                                                <div><b>Scheduled On:</b></div>
                                                <div><small>{getLocalDate(meeting.createdAt)}</small></div>
                                            </div>
                                            <div className="detail">
                                                <div><b>Scheduled With:</b></div>
                                                <div><small>{meeting.candidateName}</small></div>
                                            </div>
                                            <div className="detail">
                                                <div><b>Meeting Time</b></div>
                                                <div><small>{getLocalDate(meeting.meetingTime)}</small></div>
                                            </div>
                                            <div className="detail">
                                                <div><b>Meeting Title</b></div>
                                                <div><small>{meeting.meetingPurpose}</small></div>
                                            </div>
                                        </>
                                    }
                                >
                                    <div className="detail">
                                        <div><b>Meeting ID</b></div>
                                        <div><small>{getMeetingValue('zoomMeetingId', meeting)}</small></div>
                                    </div>
                                    <div className="detail">
                                        <div><b>Meeting link</b></div>
                                        <div><a target="_blank" rel="noopener noreferrer" href={getMeetingValue('zoomMeetingLink', meeting)} className="textWrapper"><small>{getMeetingValue('zoomMeetingLink', meeting)}</small></a></div>
                                    </div>
                                    <div className="detail">
                                        <div><b>Track name</b></div>
                                        <div className='textWrapper'><small>{meeting.trackName || "Not available"}</small></div>
                                    </div>
                                    <div className="detail">
                                        <div><b>Service Type</b></div>
                                        <div className='textWrapper'><small>{meeting.serviceType || "Not available"}</small></div>
                                    </div>
                                    <div className="detail">
                                        <div><b>Candidate's resume</b></div>
                                        <div><small>
                                            {meeting.resumeUrl ? <div className='d-flex align-items-center py-1'>
                                                <span className="mr-1">{getFileNameFromURL(meeting.resumeUrl)}</span>
                                            </div>
                                                : 'Not available'}</small></div>
                                    </div>
                                    {meeting?.focusedModules && renderMeetingModules("Meeting Modules", meeting.focusedModules)}
                                    <div className="detail col-12 p-0 mb-3">
                                        <CandidateNotesComponent meeting={meeting} />
                                    </div>
                                    {meeting.candidateFeedback && <div className="detail col-12 p-0 mb-3">
                                        <CandidateReviewComponent meeting={meeting} getMeetings={() => getResult(getSearchObj())} />
                                    </div>}
                                    {meeting.expertFeedback && <div className="detail col-12 p-0 mb-3">
                                        <ExpertFeedbackComponent meeting={meeting} getMeetings={() => getResult(getSearchObj())} />
                                    </div>}
                                    <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>

                                    <div className="d-flex justify-content-end">
                                        <Button className="ml-2 btn-sm" onClick={() => handleMeetingNotes(meeting)}>{'Feedback Details'}</Button>
                                    </div>
                                    <div>
                                         {meeting.zoomMeetingRecordedVideoLink && (
                                             <Button variant="info" onClick={() => { setSelectedMeeting(meeting); setZoomRecordingInfo(true); }}>
                                            Zoom Meeting Recording
                                           </Button>
                                           )}
                                    </div>
                                           </div>
                                </Collapsible>
                            </div>
                            {meeting?.fraudInfo?.fraud &&
                                <>
                                    <RejectedTracked data-tip data-for={'fraud-info-past' + ind}>Fraud-Candidate</RejectedTracked>
                                    <ReactTooltip id={'fraud-info-past' + ind} type="dark" place='left' >
                                        {meeting?.fraudInfo?.fraudReason}
                                    </ReactTooltip>
                                </>
                            }
                        </SubmissionContent>
                    </div>
                ))}
                {totalCount > DefaultPaginationCount && meetings?.length != 0 && !meetingLoading && <Paginator
                    count={DefaultPaginationCount}
                    total={totalCount}
                    skipcount={skipCount}
                    onAction={handlePaginationAction}
                />}

            {zoomRecordingInfo && selectedMeeting && (
             <ZoomMeetingModal
             show={zoomRecordingInfo}
             handleClose={() => setZoomRecordingInfo(false)}
             meetings={selectedMeeting}
          />
               )}
            </Main>
        </CompletedMeetingWrapper>
    )
}

export default CompletedMeetings;
