import React from "react";
import { ModalComponent } from "components/Modals/Modal";
import styled from "styled-components";

const Main = styled.div`
  padding: 1rem;
  text-align: center;

  .video-wrapper {
    width: 100%;
    max-width: 800px;
    margin: auto;
  }

  .password {
    font-weight: bold;
    color: #333;
  }

  a {
    word-break: break-word;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
      .close-button {
    position: absolute;
    top: -35px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    color: #333;
    transition: color 0.2s;

    &:hover {
      color: #000;
    }
  }
`;

interface ZoomMeetingModalProps {
  show: boolean;
  handleClose: () => void;
  meetings: {
    zoomMeetingRecordedVideoLink?: string;
    zoomMeetingRecordedVideoLinkPassword? :string;
  };
}

const ZoomMeetingModal: React.FC<ZoomMeetingModalProps> = ({ show, handleClose, meetings }) => {
  if (!meetings?.zoomMeetingRecordedVideoLink) return null;

  return (
    <ModalComponent show={show} handleClose={handleClose} >
      <Main>
      <button className="close-button" onClick={handleClose}>&times;</button>
        <div className="video-wrapper">
          <p>
            <b>Recorded zoom meeting video link:</b> <br />
            <a href={meetings.zoomMeetingRecordedVideoLink} target="_blank" rel="noopener noreferrer">
              Click here to view on Zoom
            </a>
          </p>
          {meetings.zoomMeetingRecordedVideoLinkPassword && (
            <p className="password">
              <b>Recorded zoom meeting video password:</b> {meetings.zoomMeetingRecordedVideoLinkPassword}
            </p>
          )}
        </div>
      </Main>
    </ModalComponent>
  );
};

export default ZoomMeetingModal;
