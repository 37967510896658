import { Avatar, Grid } from '@mui/material';
import RichTextEditor from 'components/Common/Editors/RichTextEditor';
import { ModalComponent } from 'components/Modals/Modal';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { jobsService } from 'services/jobs';
import styled from 'styled-components';
import { RemindersObject } from 'types/Jobs';
import { getDateTimeInLocalFormat } from 'utilities/commonUtils';
import { DefaultToastSettings } from 'utilities/defaults';
import { removeHTML } from 'utilities/utils';
import * as Yup from 'yup';
import { Checkbox, FormControlLabel } from "@mui/material";
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { getCandidateDetails, getCandidateEvaluationStatus } from "services/jobPipeline";
import { useJobPipeline } from 'containers/JobPipeline/contexts/JobPipelineContext';
import { API_STATUSES, BASE_PORTAL_URL, DEFAULT_TOKEN } from 'utilities/constants';
import { buildUrlWithParams } from 'utilities/commonUtils';
import { candidateService } from 'services/candidate';

const Main = styled.div`
    padding: 1.5rem;
    .reminder-display-container {
        display: flex;
        gap: 1rem;
        padding: 1rem;
        margin-top: 1rem;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    }
`;

const ValidationSchema = Yup.object({
    subject: Yup.string()
        .required("Subject is required"),
    title: Yup.string()
        .required("Title is required"),
});

type IProps = {
    handleClose: (e: boolean) => void;
    show: boolean;
    expertId: string,
    jobId: string,
    candidateId: string,
    backdropClassName?: string;
    candidateTrackId?: string;
    trackId?: string;
    incrementReminderCount: () => void;
}

const SendCustomReminder = (props: IProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [reminders, setReminders] = useState<RemindersObject[]>([]);
    const [sendOnlineTestLink, setSendOnlineTestLink] = useState<boolean>(false);
    const [askCandidateScheduleInterview, setAskCandidateScheduleInterview] = useState<boolean>(false);
    const { expertConfig } = useSelector((state: RootState) => state.auth);
    const permSendOnlineTest = expertConfig?.permissions?.sendOnlineTest?.view || false;
    const permCandidateScheduleInterview = expertConfig?.permissions?.candidateScheduleInterview?.view || false;
    const dispatch = useDispatch();
    const { currentJob } = useJobPipeline();
    const [candidateName, setCandidateName] = useState<string>("there");
    const [rteUpdateCounter, setRteUpdateCounter] = useState<number>(0);

    const askCandidateRTEContent = 
`<p style="font-size: 14px; font-weight: 400; line-height: 20px; color: #666666;">
Hello «NAME»,<br/>
I hope this email finds you well. I am writing to inform you that you have been selected for a job opportunity in the «JOBNAME» position at «COMPANYNAME», based on your impressive skillset.<br/>
To proceed with the opportunity, we kindly ask you to evaluate your skillset by clicking on the link provided below. Our team of FAANG experts will review your scores to ensure that you meet the necessary requirements to qualify for the position.<br/>
We appreciate your interest in joining our team and we look forward to hearing back from you soon.<br/>
<p><a href="«MEETINGLINK»" style="background-color: #007bff; color: white; padding: 15px 30px; border: none; border-radius: 30px; cursor: pointer;">Book Meeting</a></p><br/>
Best regards,<br/>«COMPANYNAME» Team</p>
`;

    const sendEvalLinkRTEContent = 
`<p style="font-size: 14px; font-weight: 400; line-height: 20px; color: #666666;">
Hello «NAME»,<br/>
I hope this email finds you well. I am writing to inform you that you have been selected for a job opportunity in the «JOBNAME» position at «COMPANYNAME», based on your impressive skillset.<br/>
To proceed with the opportunity, we kindly ask you to evaluate your skillset by clicking on the link provided below. Our team of FAANG experts will review your scores to ensure that you meet the necessary requirements to qualify for the position.<br/>
We appreciate your interest in joining our team and we look forward to hearing back from you soon.<br/>
<p><a href="«MEETINGLINK»" style="background-color: #007bff; color: white; padding: 15px 30px; border: none; border-radius: 30px; cursor: pointer;">Evaluation Link</a></p><br/>
Best regards,<br/>«COMPANYNAME» Team</p>
`;

    useEffect(() => {
        getCandidateReminder();
    }, [])

    useEffect(() => {
        const fetchCandidateDetails = async () => {
          try {
            const response = await getCandidateDetails(
              props.expertId,
              props.candidateId,
              props.jobId
            );
    
            if (response && response.candidates) {
              setCandidateName(response?.candidates?.name);
            }
          } catch (error) {
          
          }
        };
    
        if (props.candidateId && props.expertId && props.jobId) {
          fetchCandidateDetails();
        }
      }, []);

    const getCandidateReminder = () => {
        setLoading(true);
        jobsService.getCandidateReminder({
            expertId: props.expertId,
            jobId: props.jobId,
            candidateId: props.candidateId
        }).then((res) => {
            setReminders([...res.output]);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleSendCustomReminder = (formData: { subject: string, title: string }) => {
        setLoading(true); setSending(true);
        jobsService.sendCustomReminder({
            expertId: props.expertId,
            jobId: props.jobId,
            candidateId: props.candidateId,
            subject: formData.subject,
            title: formData.title,
            includeEvaluationLink: sendOnlineTestLink, 
            includeBookMeetingLink: askCandidateScheduleInterview
        }).then((res) => {
            toast.success("Reminder sent successfully", DefaultToastSettings);
            setReminders(prev => ([{ ...res.output }, ...prev]));
            props.incrementReminderCount();
            props.handleClose(false);
        }).catch(e => {
            toast.error("Something went wrong, please try again", DefaultToastSettings);
        }).finally(() => {
            setLoading(false); setSending(false);
        });
    }

    const setMessageFieldValue = (setFieldValue: (name: string, value: string) => void, data: string) => {
        if (data){
            data = data.replaceAll(
                /<p>/g,
                '<p style="font-size: 14px; font-weight: 400; line-height: 20px; color: #666666;">'
              );
        }
        
        setFieldValue("subject", data || "");
    }

    const getContent = async (baseContent: string, urlBasePath: string) => {
        let AIEvalContent = baseContent;

        let companyName = currentJob?.companyInfo?.companyName || "InterviewHelp.io";
        let jobName = currentJob?.jobTitle || "";

        setLoading(true);

        const response = await candidateService.getCandidateAuthorizationToken({ expertId: props.expertId, token: DEFAULT_TOKEN, candidateId: props.candidateId });
        if (response.apiStatus === API_STATUSES.SUCCESS) {
            const { authorizationToken: token } = response.output;
            let urlParams = {
                candidateId: props.candidateId,
                trackid: props.trackId,
                candidateTrackId: props.candidateTrackId,
                authorizationToken: token,
                jobId: props.jobId
            }

            const meetinglink = buildUrlWithParams(BASE_PORTAL_URL + urlBasePath, urlParams);

            AIEvalContent = AIEvalContent.replace("«NAME»", candidateName)
                                .replace("«JOBNAME»", jobName)
                                .replaceAll("«COMPANYNAME»", companyName)
                                .replace("«MEETINGLINK»", meetinglink);

            setLoading(false);
            return AIEvalContent;
        } else {
            toast.error("Something went wrong while generating description, please try again");
            setLoading(false);
            return "";
        }
    }

    const getTitle = () => {
        let companyName = currentJob?.companyInfo?.companyName || "InterviewHelp.io";
        let jobName = currentJob?.jobTitle || "";

        return `Exciting Opportunity: ${jobName} Position at ${companyName}`;
    }



    const handleChangeSendOnlineTestLink = async (checked: boolean, setFieldValue: (name: string, value: string) => void) => {
        setSendOnlineTestLink(checked);
        if (!checked) return;

        let subjectContent = await getContent(sendEvalLinkRTEContent, "onboardCandidate");
        setFieldValue("subject", subjectContent);
        setFieldValue("title", getTitle());
        setRteUpdateCounter(rteUpdateCounter + 1);
        setAskCandidateScheduleInterview(false);
    }

    const handleChangeAskCandidateScheduleInterview = async (checked: boolean, setFieldValue: (name: string, value: string) => void) => {
        setAskCandidateScheduleInterview(checked);
        if (!checked) return;
        
        let subjectContent = await getContent(askCandidateRTEContent, "meetings");
        setFieldValue("subject", subjectContent);
        setFieldValue("title", getTitle());
        setRteUpdateCounter(rteUpdateCounter + 1);
        setSendOnlineTestLink(false);
    }

    return (
        <ModalComponent
            header={"Send a Message"}
            modalStyle={{ zIndex: '9999' }}
            handleClose={() => props.handleClose(false)}
            show={props.show}
            showCloseIcon={true}
            backdropClassName={props.backdropClassName}
            size='xl'
        >
            <Main>
                {loading && <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            zIndex: 1000,
                        }}
                    >
                    <Loader loading={true} size={50} color={'blue'} />
                </div>}
                <Formik
                    initialValues={{title: "", subject: ""}}
                    enableReinitialize
                    onSubmit={handleSendCustomReminder}
                    validationSchema={ValidationSchema}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form>
                            <div className="mb-3">
                                {permSendOnlineTest && props.candidateTrackId && props.trackId && <FormControlLabel
                                        checked={sendOnlineTestLink}
                                        control={<Checkbox onChange={(e) => handleChangeSendOnlineTestLink(e.target.checked, setFieldValue)} />}
                                        label={"Send Evaluation Link"}
                                    />}
                                {permCandidateScheduleInterview && props.candidateTrackId && props.trackId && <FormControlLabel
                                        checked={askCandidateScheduleInterview}
                                        control={<Checkbox onChange={(e) => handleChangeAskCandidateScheduleInterview(e.target.checked, setFieldValue)} />}
                                        label={"Ask Candidate for meeting"}
                                    />}
                            </div>
                            <div className='mb-3'>
                                <Field name='title' title='Enter title' type='text' className="form-control" placeholder="Enter title" required />
                                {errors.title && touched.title && (
                                    <div className="text-danger">{errors.title}</div>
                                )}
                            </div>
                            <div className='mb-3'>
                                <Field
                                    className="form-control"
                                    name={'subject'}
                                    required
                                >
                                    {() => (
                                        <RichTextEditor
                                            key={"valueUpdated_" + rteUpdateCounter}
                                            value={values.subject}
                                            disabled={false}
                                            onChange={(data: string) => setMessageFieldValue(setFieldValue, data)}
                                            id={'subject'}
                                            customStyles={{ height: "300px", boxShadow: 'none' }}
                                            placeholder='Enter your subject'
                                        />
                                    )}
                                </Field>
                                {errors.subject && touched.subject && (
                                    <div className="text-danger">{errors.subject}</div>
                                )}
                            </div>                            
                            <button className="btn btn-primary" type="submit" disabled={sending}>
                                {!sending ? "Send Custom Reminder": "Sending..."}
                            </button>
                        </Form>
                    )}
                </Formik>
                
            </Main>
        </ModalComponent>
    );
}


export default SendCustomReminder;