import ForgotPassword from 'containers/ForgotPassword';
import StandAloneResume from 'containers/JobPipeline/components/Resume/StandAloneResume';
import ResetPassword from 'containers/ResetPassword';
import { JobPipeline } from 'pages/B2B/JobManagement/JobPipeline';
import { JobsManagementPage } from 'pages/B2B/JobManagement/JobsManagementPage';
import { NetworkJobsManagement } from 'pages/B2B/JobManagement/NetworkJobsManagement';
import Tasks from 'pages/B2B/Jobs/Tasks';
import { NetworkJobs } from 'pages/B2B/NetworkJobs';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setValueBrowserStorage } from 'services/browserStorageService';
import { CandidateSearchPage } from '../../pages/B2B/CandidateSearch';
import { JobsPage } from '../../pages/B2B/Jobs';
import { MyDeskPage } from '../../pages/B2B/MyDesk';
import { CandidatePage } from '../../pages/Candidate';
import { ClassesPage } from '../../pages/Classes';
import { DashboardPage } from '../../pages/Dashboard';
import { FeedbackPage } from '../../pages/Feedback';
import { LoginPage } from '../../pages/Login';
import { MeetingPage } from '../../pages/Meeting';
import { MeetingDetailPage } from '../../pages/MeetingDetail';
import Profile from '../../pages/Profile';
import { SettingsPage } from '../../pages/Settings/SettingsPage';
import { SignupPage } from '../../pages/Signup';
import { VideosPage } from '../../pages/Videos';
import { MenuVisibilityProvider } from '../../providers/menuVisibilityProvider';
import BrowserCacheService from '../../services/browser-cache';
import store from '../../store';
import GlobalStyle from '../../theme/globalStyles';
import { EXPERT_USER_TYPE, FIRST_URL_KEY } from '../../utilities/constants';
import { ChangePasswordPage } from '../ChangePassword';
import { ErrorFallback } from '../ErrorFallback/ErrorFallback';
import { PrivateRoute } from '../PrivateRoute';
import { CampaignDetailsPage } from 'pages/B2B/JobManagement/CampaingnDetails';
import { LeadListDetails } from 'pages/B2B/JobManagement/LeadListDetails';

export const App = (props: any) => {
  BrowserCacheService.setItem("userType", EXPERT_USER_TYPE);
  setValueBrowserStorage(FIRST_URL_KEY, window.location.pathname + window.location.search);

  useEffect(() => {
    toast.configure();
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
      >
        <GlobalStyle />
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={LoginPage} exact />
            <Route path="/signup" component={SignupPage} exact />
            <Route path="/change-password" component={ChangePasswordPage} exact />
            <Route path="/forgot-password" component={ForgotPassword} exact />
            <Route path="/resetPassword" component={ResetPassword} exact />
            <Route 
              path="/resume"
              exact
              component={StandAloneResume}
            />
            <MenuVisibilityProvider>
              <PrivateRoute path="/" component={DashboardPage} exact />
              <PrivateRoute path="/dashboard" component={DashboardPage} exact />
              <PrivateRoute path="/your-candidates" component={CandidatePage} exact />
              <PrivateRoute path="/meeting" component={MeetingDetailPage} exact />
              <PrivateRoute path="/your-meetings" component={MeetingPage} exact />
              <PrivateRoute path="/schedule-meetings" component={MeetingPage} exact />
              <PrivateRoute path="/submissions" component={FeedbackPage} exact />
              <PrivateRoute path="/profile" component={Profile} exact />
              <PrivateRoute path="/videos" component={VideosPage} exact />
              <PrivateRoute path="/settings" component={SettingsPage} exact />
              <PrivateRoute path="/jobs" component={JobsPage} exact />
              <PrivateRoute path="/job-search" component={JobsManagementPage} exact />
              <PrivateRoute path="/campaign" component={CampaignDetailsPage} exact />
              <PrivateRoute path="/lead-list-details" component={LeadListDetails} exact />
              <PrivateRoute path="/my-desk" component={MyDeskPage} exact />
              <PrivateRoute path="/candidate-search" component={CandidateSearchPage} exact />
              <PrivateRoute path="/job-pipeline" component={JobPipeline} exact />
              <PrivateRoute path="/network-jobs" component={NetworkJobs} exact />
              <PrivateRoute path="/network-job-search" component={NetworkJobsManagement} exact />
              <PrivateRoute path="/tasks" component={Tasks} exact />
              <PrivateRoute path="/classes" component={ClassesPage} exact />
            </MenuVisibilityProvider>
          </Switch>
        </BrowserRouter>
      </ErrorBoundary>
    </Provider >
  );
};
